<template>
  <div class="flex-grow">
    <div class="flex items-center justify-center h-full">
      <div class="otp-wrapper">
        <Form @submit="handleGetOTP" :validation-schema="schema">
          <div class="m-auto pb-2 pt-1 px-4 rounded-3xl" style="min-width: 24rem; background: #63c289">
            <div class="card-body">
              <center>
                <h3>ลืมรหัสผ่าน</h3>
                <p>กรุณากรอกหมายเลขโทรศัพท์</p>
              </center>
              <br />
              <div class="col-sm-12">
                <Field name="phone" type="text" class="form-control" placeholder="กรุณากรอกเบอร์โทรศัพท์มือถือ" />
                <ErrorMessage name="phone" class="error-feedback" />
                <br />
              </div>
              <br />
              <div class="d-grid gap-2 mt-3">
                <button class="btn btn-secondary btn-lg" :disabled="loading">
                  <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                  <span>ขอรหัสยืนยันตัวตน</span>
                </button>
                <div class="form-group">
                  <div v-if="message" class="alert alert-danger" role="alert">
                    {{ message }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate'
import * as yup from 'yup'

export default {
  name: 'forgetpassword',
  components: {
    Form,
    Field,
    ErrorMessage
  },
  data() {
    const schema = yup.object().shape({
      phone: yup.string().required('phone is required!')
    })

    return {
      loading: false,
      message: '',
      schema
    }
  },
  methods: {
    handleGetOTP(user) {
      this.loading = true

      this.$store.dispatch('auth/forgetpassword', user).then(
        () => {
          this.$router.push('/confirmotp')
        },
        (error) => {
          this.loading = false
          this.message = 'Invalid number!'
        }
      )
    }
  }
}
</script>

<style scoped>
h3 {
  color: #fff;
}
</style>
